/* eslint-disable check-file/folder-naming-convention */
import dynamic from 'next/dynamic';

export const BannerV2 = dynamic(() => import(/* webpackChunkName: "BannerV2" */'@/features/banner/banner-v2/banner-v2'));
export const ThirdViewCancellationV2 = dynamic(() => import(/* webpackChunkName: "ThirdViewCancellationV2" */'@/features/third-view-cancellation-v2/third-view-cancellation-v2'));
export const ShareV1 = dynamic(() => import(/* webpackChunkName: "ShareV1" */'@/features/share-v1/share-v1'));
export const GroupV1 = dynamic(() => import(/* webpackChunkName: "GroupV1" */'@/features/group-v1/group-v1'));
export const ClimateDiagramsV2 = dynamic(() => import(/* webpackChunkName: "ClimateDiagramsV2" */'@/features/climate-diagrams-v2/climate-diagrams-v2'));
export const HeadlineV1 = dynamic(() => import(/* webpackChunkName: "HeadlineV1" */'@/features/headline-v1/headline-v1'));
export const WindyTileV1 = dynamic(() => import(/* webpackChunkName: "WindyTileV1" */'@/features/windy-tile-v1/windy-tile-v1'));
export const ActivityTilesV1 = dynamic(() => import(/* webpackChunkName: "ActivityTilesV1" */'@/features/activity-tiles/activity-tiles-v1/activity-tiles-v1'));
export const TextV1 = dynamic(() => import(/* webpackChunkName: "TextV1" */'@/features/text-v1/text-v1'));
export const LandmarkTilesV1 = dynamic(() => import(/* webpackChunkName: "LandmarkTilesV1" */'@/features/landmark-tiles/landmark-tiles-v1/landmark-tiles-v1-container'));
export const StickyCtaV1 = dynamic(() => import(/* webpackChunkName: "StickyCtaV1" */'@/features/sticky-cta-v1/sticky-cta-v1'));
export const TrustV1 = dynamic(() => import(/* webpackChunkName: "TrustV1" */'@/features/trust/trust-v1/trust-component-switch/trust-component-switch'));
export const LinkCtaV1 = dynamic(() => import(/* webpackChunkName: "LinkCtaV1" */'@/features/link-cta-v1/link-cta-v1'));
export const FilterBarV2 = dynamic(() => import(/* webpackChunkName: "FilterBarV2" */'@/features/filter/filter-bar-v2/filter-bar-v2'));
export const QuickFilterV1 = dynamic(() => import(/* webpackChunkName: "QuickFilterV1" */'@/features/filter/quick-filter/quick-filter/quick-filter-container'));
export const OtherActivitiesV1 = dynamic(() => import(/* webpackChunkName: "OtherActivitiesV1" */'@/features/other-activities-v1/other-activities-v1-container'));
export const TippV1 = dynamic(() => import(/* webpackChunkName: "TippV1" */'@/features/tipp-v1/tipp-v1'));
export const ThirdViewPaymentInfoV1 = dynamic(() => import(/* webpackChunkName: "ThirdViewPaymentInfoV1" */'@/features/third-view-payment-information-v1/third-view-payment-information-v1'));
export const PlacesV2 = dynamic(() => import(/* webpackChunkName: "PlacesV2" */'@/features/places/places-v2/places-v2'));
export const KeyFactsV2 = dynamic(() => import(/* webpackChunkName: "KeyFactsV2" */'@/features/key-facts-v2/key-facts-v2'));
export const SchoolTilesV2 = dynamic(() => import(/* webpackChunkName: "SchoolTilesV2" */'@/features/schools/school-tiles-v2/school-tiles-v2'));
export const ScrollToComponentCtaV1 = dynamic(() => import(/* webpackChunkName: "ScrollToComponentCtaV1" */'@/features/scroll-to-component-cta-v1/scroll-to-component-cta-v1'));
export const SpotHeaderV2 = dynamic(() => import(/* webpackChunkName: "SpotHeaderV1" */'@/features/spot-header/spot-header-v2/spot-header-v2'));
export const GalleryV1 = dynamic(() => import(/* webpackChunkName: "GalleryV1" */'@/features/gallery-v1/gallery-v1'));
export const InfoBoxV1 = dynamic(() => import(/* webpackChunkName: "InfoBoxV1" */'@/features/info-box-v1/info-box/info-box-v1'));
export const OnpageSearchCtaV1 = dynamic(() => import(/* webpackChunkName: "OnPageSearchCtaV1" */'@/features/onpage-search/onpage-search-cta-v1/onpage-search-cta-v1'));
export const SpotResultsV4 = dynamic(() => import(/* webpackChunkName: "SpotResultsV4" */'@/features/spot-results/spot-results-v4/spot-results-v4'));
export const SpotTilesV3 = dynamic(() => import(/* webpackChunkName: "SpotTilesV2" */'@/features/spot-tiles/spot-tiles-v3/spot-tiles-v3'));
export const MapPreviewV1 = dynamic(() => import (/* webpackChunkName: "MapPreviewV1" */'@/features/map/map-preview-v1/map-preview-v1-container'));
export const SeasonCalendarV2 = dynamic(() => import (/* webpackChunkName: "SeasonCalendarV2" */'@/features/season-calendar/season-calendar-v2/season-calendar-v2'));
export const CustomerFeedbackV1 = dynamic(() => import (/* webpackChunkName: "CustomerFeedbackV1" */'@/features/customer-feedback/customer-feedback-v1/customer-feedback-v1'));
export const OverlayCtaV1 = dynamic(() => import (/* webpackChunkName: "OverlayCtaV1" */'@/features/overlay-cta-v1/overlay-cta-v1'));
export const ActivityNavigationV1 = dynamic(() => import (/* webpackChunkName: "ActivityNavigationV1" */'@/features/activity-navigation-v1/activity-navigation-v1'));
export const AccommodationTilesV1 = dynamic(() => import (/* webpackChunkName: "AccommodationTilesV1" */'@/features/accommodation-tiles/accommodation-tiles-v1/accommodation-tiles-v1-container'));
export const NavigationBarV1 = dynamic(() => import(/* webpackChunkName: "NavigationBarV1" */'@/features/navigation-bar-v1/navigation-bar-v1'));
export const WishlistButtonV1 = dynamic(() => import (/* webpackChunkName: "WishlistButtonV1" */'@/features/wishlist/wishlist-button-v1/wishlist-button-v1'));
export const TravelFormCtaV1 = dynamic(() => import (/* webpackChunkName: "TravelFormCtaV1" */'@/features/travel-form/travel-form-cta-v1'));
export const SchoolHeaderV1 = dynamic(() => import (/* webpackChunkName: "SchoolHeaderV1" */'@/features/school-header-v1/school-header-v1'));
export const SeaBannerV1 = dynamic(() => import (/* webpackChunkName: "SeaBannerV1" */'@/features/banner/sea-banner-v1/sea-banner-v1'));
export const MediaSwiperV1 = dynamic(() => import (/* webpackChunkName: "MediaSwiperV1" */'@/features/media-swiper-v1/media-swiper-v1'));
export const SpotSelectorV2 = dynamic(() => import (/* webpackChunkName: "SpotSelectorV2" */'@/features/spot-selector-v2/spot-selector-v2'));
export const CheckoutParticipantsV1 = dynamic(() => import (/* webpackChunkName: "CheckoutParticipantsV1" */'@/features/checkout/checkout-participants-v1/checkout-participants-v1'));
export const CheckoutParticipantsFormV1 = dynamic(() => import (/* webpackChunkName: "CheckoutParticipantsFormV1" */'@/features/checkout/checkout-participants-form-v1/checkout-participants-form-v1'));
export const CheckoutParticipantsSelectionV1 = dynamic(() => import (/* webpackChunkName: "CheckoutParticipantsSelectionV1" */'@/features/checkout/checkout-participants-selection-v1/checkout-participants-selection-v1'));
export const CheckoutAddressFormV1 = dynamic(() => import (/* webpackChunkName: "CheckoutAddressFormV1" */'@/features/checkout/checkout-address-form-v1/checkout-address-form-v1'));
export const CheckoutHeaderV1 = dynamic(() => import (/* webpackChunkName: "CheckoutHeaderV1" */'@/features/checkout/checkout-header-v1/checkout-header-v1'));
export const CheckoutSummaryV1 = dynamic(() => import (/* webpackChunkName: "CheckoutSummaryV1" */'@/features/checkout/checkout-summary-v1/checkout-summary-v1'));
export const CheckoutAddressV1 = dynamic(() => import (/* webpackChunkName: "CheckoutAddressV1" */'@/features/checkout/checkout-address-v1/checkout-address-v1'));
export const CheckoutAddressSelectionV1 = dynamic(() => import (/* webpackChunkName: "CheckoutAddressSelectionV1" */'@/features/checkout/checkout-address-selection-v1/checkout-address-selection-v1'));
export const ThirdViewOverlayV1 = dynamic(() => import (/* webpackChunkName: "ThirdViewOverlayV1" */'@/features/third-view-overlay-v1/third-view-overlay-v1'));
export const ContactBarV1 = dynamic(() => import (/* webpackChunkName: "ContactBarV1" */'@/features/contact-bar-v1/contact-bar-v1'));
export const HintV1 = dynamic(() => import (/* webpackChunkName: "HintV1" */'@/features/hint-v1/hint-v1'));
export const ThirdViewContactInfoV1 = dynamic(() => import (/* webpackChunkName: "ThirdViewContactInfoV1" */'@/features/third-view-contact-information-v1/third-view-contact-information-v1'));
export const ThirdViewHeaderV1 = dynamic(() => import (/* webpackChunkName: "ThirdViewHeaderV1" */'@/features/third-view-header-v1/third-view-header-v1'));
export const ThirdViewBookingInfoV1 = dynamic(() => import (/* webpackChunkName: "ThirdViewBookingInfoV1" */'@/features/third-view-booking-info-v1/third-view-booking-info-v1'));
export const ThirdViewOfferV1 = dynamic(() => import (/* webpackChunkName: "ThirdViewOfferV1" */'@/features/third-view-offer-v1/third-view-offer-v1'));
export const SchoolProductTilesV2 = dynamic(() => import (/* webpackChunkName: "SchoolProductTilesV2" */'@/features/school-product-tiles-v2/school-product-tiles-v2'));
export const OnpageSearchSeaBannerV1 = dynamic(() => import (/* webpackChunkName: "OnpageSearchSeaBannerV1" */'@/features/onpage-search-sea-banner-v1/onpage-search-sea-banner-v1'));
export const CheckoutPaymentCreditCardV1 = dynamic(() => import (/* webpackChunkName: "CheckoutPaymentCreditCardV1" */'@/features/checkout/checkout-payment/checkout-payment-credit-card-v1/checkout-payment-credit-card-v1'));
export const CheckoutPaymentCreditCardFormV1 = dynamic(() => import (/* webpackChunkName: "CheckoutPaymentCreditCardFormV1" */'@/features/checkout/checkout-payment/checkout-payment-credit-card-form-v1/checkout-payment-credit-card-form-v1'));
export const CheckoutPaymentCreditCardSelectionV1 = dynamic(() => import (/* webpackChunkName: "CheckoutPaymentCreditCardSelectionV1" */'@/features/checkout/checkout-payment/checkout-payment-credit-card-selection-v1/checkout-payment-credit-card-selection-v1'));
export const LoginV1 = dynamic(() => import (/* webpackChunkName: "LoginV1" */'@/features/login-v1/login-v1'));
export const CartV1 = dynamic(() => import (/* webpackChunkName: "CartV1" */'@/features/cart/cart-v1/cart-v1'));
export const TravelFormV1 = dynamic(() => import (/* webpackChunkName: "TravelFormV1" */'@/features/travel-form/travel-form-v1/travel-form-v1-container'));
export const RoutesV1 = dynamic(() => import (/* webpackChunkName: "RoutesV1" */'@/features/routes-v1/routes-v1'));
export const PieChartV1 = dynamic(() => import (/* webpackChunkName: "PieChartV1" */'@/features/pie-chart-v1/pie-chart-v1'));
export const ActivityAreaTilesV1 = dynamic(() => import(/* webpackChunkName: "ActivityAreaTilesV1" */'@/features/activity-area-tiles/activity-area-tiles-v1/activity-area-tiles-v1'));
export const ContinueSearchV1 = dynamic(() => import(/* webpackChunkName: "ContinueSearchV1" */'@/features/continue-search/continue-search-v1/continue-search-v1'));
export const ContinueBookingV1 = dynamic(() => import(/* webpackChunkName: "ContinueBookingV1" */'@/features/continue-booking/continue-booking-v1/continue-booking-v1'))
export const MapBottomSheetV1 = dynamic(() => import(/* webpackChunkName: "MapBottomSheetV1" */'@/features/map/map-bottom-sheet-v1/map-bottom-sheet-v1'))
export const MapButtonStickyV1 = dynamic(() => import(/* webpackChunkName: "MapButtonStickyV1" */'@/features/map/map-button-sticky-v1/map-button-sticky-v1'))
export const SkiSlopeFactsV1 = dynamic(() => import(/* webpackChunkName: "SkiSlopeFactsV1" */'@/features/ski-slope/ski-slope-facts-v1/ski-slope-facts-v1'))
export const SkiSlopePreviewV1 = dynamic(() => import(/* webpackChunkName: "SkiSlopePreviewV1" */'@/features/ski-slope/ski-slope-preview-v1/ski-slope-preview-v1'))
export const HighlightHeaderV1 = dynamic(() => import(/* webpackChunkName: "HighlightHeaderV1" */'@/features/highlight-header/highlight-header-v1/highlight-header-v1'))
export const PpsetTeaserVerticalChipsV1 = dynamic(() => import(/* webpackChunkName: "PpsetTeaserVerticalChipsV1" */'@/features/vertical-widget/ppset-teaser/vertical-chips/ppset-teaser-vertical-chips-v1'))
